import React from 'react';

//Styles
import '../styles/About.css';

//Resume file
import Resume from '../docs/Jonathan-Lopez-Resume2024.pdf'

const About = () => {
    return (
        <>
            <div id="about" className="container flex flex-col justify-center text-white mt-24 md:w-[40%]">

                <div className="px-10 flex flex-col items-center">
                    <h2 className="text-3xl mb-4">About Me</h2>
                    <p className="text-pretty"> I’m a full stack developer with a strong foundation in modern web technologies, including React, Express.js, PostgreSQL, TypeScript, and TailwindCSS. My journey into programming began through a comprehensive bootcamp with the University of Massachusetts, where I developed the skills to build dynamic, responsive, and scalable web applications. Whether it's developing intuitive user interfaces or designing robust databases, I thrive in creating solutions that deliver real-world impact. </p>

                    <button className="border px-5 py-3 mt-5 ease-in duration-300 hover:bg-yellow-500">
                        <a href={Resume} download="JonathanLopezResume2024" target="_blank" className="md:w-[100%]">
                            Download My Resume
                        </a>
                    </button>
                </div>

                
               

            </div>
        </>
    );
};

export default About;