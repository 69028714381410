import React from 'react';

//Components
import Card from './Card';

//images
import mechanicPreview from '../images/JA_Preview.png'
import ShoppPreview from '../images/ShoppPreview.png'

//styles
// import '../styles/Projects.css'

const Projects = () => {

    return (
        <>
            <div id="Projects" className="container flex flex-col justify-center items-center text-white mb-20 mt-32 w-[85vw]">
                <h2 className="text-3xl mb-5">Gig work</h2>

                <div className="flex flex-col justify-center w-[100%] lg:flex-row">
                    <Card 
                        image={mechanicPreview}
                        title="J&A AutoServices"
                        description="A business website I designed, developed, and deployed. Worked with the client to make final design decisions and currently hosting with Render"
                        link="https://jaautoservices.com/"
                    />
                
                </div>

                <h2 className="text-3xl mb-5">Projects</h2>

                <div className="flex flex-col justify-center w-[100%] lg:flex-row">

                    <Card 
                        image={ShoppPreview}
                        title="Shopp"
                        description="An e-commerce site I built with a focus on the backend. Complete with user auth, route protection, shopping cart. Used the FakeStoreAPI for items in the store. PostgreSQL for user tables and shopping cart "
                        link="https://capstone-frontend-v4ya.onrender.com"
                    />
                
                </div>
            </div>
        </>
    )
}

export default Projects;