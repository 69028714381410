import React from "react"


const Card = ({image, title, description, link}) => {
    return (
        <>
            <div className="bg-[#324a5fb6] mb-20 lg:w-[40vw] lg: rounded-lg flex flex-col lg:justify-between items-center ease duration-300 hover:translate-y-2">
                <img src={image} className="rounded-t-lg mb-4" alt="..."/>
                <div className="p-4">
                    <h5 className="font-bold text-2xl">{title}</h5>
                    <p className="text-lg">{description}</p>
                </div>
                <button className="border px-5 py-2 mt-4 mb-4 hover:bg-yellow-500 hover:text-black"><a href={link} className="">Visit</a></button>
            </div>
        
        </>
    )
}

export default Card;